<template>
<div class="vx-row">
    <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
        <vx-card title="Filter">
            <div class="filter-container">
                <h6 class="font-bold mb-3">Sign Up Start Date</h6>
                <div>
                    <flat-pickr v-model="fromDate" placeholder="From Date" @on-change="onFromChange" class="w-full" />
                </div>
                <vs-divider />
                <h6 class="font-bold mb-4">Sign Up End Date</h6>
                <div>
                    <flat-pickr v-model="toDate" placeholder="To Date" @on-change="onToChange" class="w-full" />
                </div>
                <vs-divider />
                <div class="flex justify-center">
                    <vs-button class="w-full" slot-scope="{ canRefine, refine }" @click.prevent="refine" :disabled="!canRefine">Remove Filters</vs-button>
                </div>
            </div>
        </vx-card>
    </div>
    <div class="vx-col md:w-3/4 sm:w-1/2 w-full">
        <vx-card class="item-details mb-base welcomeCard" title="Customer Report">
            <template slot="actions">
                <vs-button color="danger" @click="downloadPdf">Report</vs-button>
                <vs-button class="ml-2" color="success" @click="downloadexcel">Export</vs-button>
            </template>
            <vs-table stripe :data="reportDataList">
                <template #thead>
                    <vs-th>Sign Up</vs-th>
                    <vs-th>Name</vs-th>
                    <vs-th>Address</vs-th>
                    <vs-th>Passes#</vs-th>
                    <vs-th>Visits#</vs-th>
                    <vs-th>Last Visit</vs-th>
                </template>
                <template>
                    <vs-tr :key="i" v-for="(tr, i) in reportDataList" :data="tr">
                        <vs-td>{{ tr.signUpDate | formatLongDate }}</vs-td>
                        <vs-td>
                            {{ tr.name }}
                        </vs-td>
                        <vs-td>
                            {{ tr.suburb }} {{ tr.state }} {{ tr.postcode }}
                        </vs-td>

                        <vs-td>
                            {{ tr.numberOfPasses }}
                        </vs-td>
                        <vs-td>
                            {{ tr.numberOfVisits }}
                        </vs-td>
                        <vs-td>{{ tr.lastVisitedDate | formatDate }}</vs-td>
                    </vs-tr>
                </template>
            </vs-table>
        </vx-card>
    </div>
</div>
</template>

<script>
import {
    customMessages
} from "./../../filters/validationmessage";

import VuePerfectScrollbar from "vue-perfect-scrollbar";
import moment from 'moment'

import {
    reportsData
} from "@/store/api/report";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'

export default {
    components: {
        flatPickr,
        VuePerfectScrollbar,
        StatisticsCardLine
    },
    data() {
        return {
            customMessages,
            startTime: null,
            endTime: null,
            isResourceLoaded: true,
            fromDate: null,
            toDate: null,
            reportDataList: [],
            isFilterSidebarActive: true,
            configFromdateTimePicker: {
                maxDate: null,
                altFormat: 'd-m-Y',
                altInput: true,
                dateFormat: 'Y-m-d',
            },
            configTodateTimePicker: {
                minDate: null,
                altFormat: 'd-m-Y',
                altInput: true,
                dateFormat: 'Y-m-d',
            },
            settings: {
                maxScrollbarLength: 60,
                wheelSpeed: 0.7
            }
        };
    },
    async created() {
        this.fromDate = moment().add(-7, 'days').format();
        this.toDate = moment().format();
        await this.generateReport();
    },
    computed: {
        scrollbarTag() {
            return this.$store.getters.scrollbarTag;
        }
    },
    methods: {
        async downloadPdf() {
            const reportParameter = {
                startDate: this.fromDate,
                endDate: this.toDate,
                productId: this.selectedProduct
            };

            let data = await reportsData.customerReportExportToPdf(reportParameter);
            window.open(data.url, '_blank').focus();
        },
        async downloadexcel() {
            const reportParameter = {
                startDate: this.fromDate,
                endDate: this.toDate,
                productId: this.selectedProduct
            };

            let data = await reportsData.customerReportExportToExcel(reportParameter);
            window.open(data.url, '_blank').focus();
        },
        async generateReport() {
            const reportParameter = {
                startDate: this.fromDate,
                endDate: this.toDate,
                productId: this.selectedProduct
            };

            this.reportDataList = await reportsData.customerReport(reportParameter);
        },
        async onFromChange(selectedDates, dateStr, instance) {
            this.$set(this.configTodateTimePicker, "minDate", dateStr);
            const date = new Date(dateStr);
            await this.generateReport();
        },
        async onToChange(selectedDates, dateStr, instance) {
            this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
            await this.generateReport();
        },
    }
};
</script>
